<template>
	<div>
		<img class="img-bg" v-if="bannerInfo" :src="bannerInfo[0]" alt="">
		<div class="main-title fx-tab">
			<div class="big-title">分销合作</div>
			<div class="small-title">—   Flying fish   —</div>
		</div>
		<div class="tab-box">
			<div v-for="item in tabList" :key="item.type" class="tab-item" :class="{'active-cls': item.type == tabIndex}" @click="chooseTab(item.type)">{{item.name}}</div>
		</div>
		<div class="advantage-box" v-if="tabIndex == 0">
			<div class="first-box">
				<div class="content-cls advantage-list">
					<div class="advantage-item">
						<img src="../assets/platform/img_1.png">
						<div class="text">一键定制</div>
					</div>
					<div class="advantage-item">
						<img src="../assets/platform/img_2.png">
						<div class="text">免费设计器</div>
					</div>
					<div class="advantage-item">
						<img src="../assets/platform/img_3.png">
						<div class="text">无限SKU</div>
					</div>
					<div class="advantage-item">
						<img src="../assets/platform/img_4.png">
						<div class="text">免费图库</div>
					</div>
					<div class="advantage-item">
						<img src="../assets/platform/img_5.png">
						<div class="text">优质产品开发</div>
					</div>
					<div class="advantage-item">
						<img src="../assets/platform/img_6.png">
						<div class="text">高效生产</div>
					</div>
					<div class="advantage-item">
						<img src="../assets/platform/img_7.png">
						<div class="text">多渠道派送</div>
					</div>
					<div class="advantage-item">
						<img src="../assets/platform/img_8.png">
						<div class="text">完善售后</div>
					</div>
				</div>
			</div>
			<div class="second-box">
				<div class="content-cls">
					<div class="small-title">为什么</div>
					<div class="big-title">选择柔性定制</div>
					<div class="inline-box">
						<div class="left-box">
							<div class="box-title">柔性定制</div>
							<div class="box-content">
								<div class="box-item" v-for="(item, index) in leftList" :key="index">
									<span>{{item.content}}</span>
									<span class="blue-cls">{{item.tag}}</span>
								</div>
							</div>
						</div>

						<div class="right-box">
							<div class="box-title">传统模式</div>
							<div class="box-content">
								<div class="box-item" v-for="(item, index) in rightList" :key="index">
									<span>{{item.content}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="after-sales-box" v-if="tabIndex == 1">
			<div class="content-cls" v-if="afterSales">
				<div class="left-box">
					<div class="title-cls">订单商品质量导致的售后问题，由平台承担全责.具体售后规则如下：</div>
					<div class="left-content" v-html="afterSales.main_rule"></div>
				</div>

				<div class="right-box">
					<div class="right-top">
						<div class="title-cls">前言</div>
						<div class="top-content" v-html="afterSales.preface"></div>
					</div>
					<div class="right-bottom">
						<div class="title-cls">订单售后规则</div>
						<div class="bottom-content" v-html="afterSales.order_rule"></div>
					</div>
				</div>
			</div>
		</div>

		<div class="cooperation-box" v-if="tabIndex == 2">
			<div class="distribution-box">
				<div class="content-cls">
					<div class="title-cls">分销流程</div>
					<div class="process-box">
						<div class="process-top">
							<div class="process-item">
								<img class="icon-cls" src="../assets/platform/distribution_1.png" alt="">
								<div class="process-title">开通账户</div>
							</div>
							<img class="arrow arrow-left" src="../assets/platform/arrow.png" alt="">
							<div class="process-item">
								<img class="icon-cls" src="../assets/platform/distribution_2.png" alt="">
								<div class="process-title">海量选品</div>
							</div>
							<img class="arrow arrow-left" src="../assets/platform/arrow.png" alt="">
							<div class="process-item">
								<img class="icon-cls" src="../assets/platform/distribution_3.png" alt="">
								<div class="process-title">一件设计</div>
							</div>
						</div>
						
						<div class="process-content">
							<div class="flex-1">我们为分销商提供独有的智能服务平台<br>实现一件生产、一件代发服务，远销各个国家和平台</div>
							<img class="arrow arrow-down" src="../assets/platform/arrow.png" alt="">
						</div>
						
						<div class="process-bottom">
							<div class="process-item">
								<img class="icon-cls" src="../assets/platform/img_8.png" alt="">
								<div class="process-title">优质售后</div>
							</div>
							<img class="arrow arrow-right" src="../assets/platform/arrow.png" alt="">
							<div class="process-item">
								<img class="icon-cls" src="../assets/platform/distribution_6.png" alt="">
								<div class="process-title">平台发货</div>
							</div>
							<img class="arrow arrow-right" src="../assets/platform/arrow.png" alt="">
							<div class="process-item">
								<img class="icon-cls" src="../assets/platform/distribution_5.png" alt="">
								<div class="process-title">下单生产</div>
							</div>
							<img class="arrow arrow-right" src="../assets/platform/arrow.png" alt="">
							<div class="process-item">
								<img class="icon-cls" src="../assets/platform/distribution_4.png" alt="">
								<div class="process-title">上架销售</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="join-box">
				<div class="content-cls">
					<div class="form-box">
						<div class="form-item">
							<div class="label-name">姓名</div>
							<input type="text" class="input-cls" v-model="params.nickname" value="飞飞鱼" placeholder="请输入您的姓名">
						</div>
						<div class="form-item">
							<div class="label-name">电话</div>
							<input type="text" class="input-cls" v-model="params.phone" placeholder="请输入您的手机号码">
						</div>
						<div class="form-item">
							<div class="label-name">邮箱</div>
							<input type="text" class="input-cls" v-model="params.email" placeholder="请输入您的邮箱">
						</div>
						<div class="form-item">
							<div class="label-name">需求</div>
							<input type="text" class="input-cls" v-model="params.demand" placeholder="请填写您的大概需求">
						</div>
						<div class="submit-btn" @click="submitFn">提交</div>
					</div>
				</div>
			</div>

			<!-- 地址 -->
			<div class="address-box ">
				<img src="../assets/address.jpg" alt="">
			</div>
		</div>

	</div>
</template>

<script>
	import {mapState} from 'vuex';
	export default {
		data() {
			return {
				tabList: [{
						name: '平台优势',
						type: 0
					},
					{
						name: '合作加盟',
						type: 2
					},
					{
						name: '售后服务',
						type: 1
					},
				],
				tabIndex: 0, // 当前tab类型
				leftList: [{
						content: '无需备货，出单即赚，资金转化率高',
						tag: '出单即赚'
					},
					{
						content: '直连优质工厂，货源稳定',
						tag: '货源稳定'
					},
					{
						content: '柔性生产，入仓效率高',
						tag: '效率更高'
					},
					{
						content: '紧跟市场趋势，品类丰富',
						tag: '品类丰富'
					},
					{
						content: '无忧后勤，专注设计及销售',
						tag: '无忧后勤'
					},
					{
						content: '无限SKU，选择性多',
						tag: '无限SKU'
					}
				],
				rightList: [{
						content: '需求预测困难、订货、库存、周转滞销成本高',
					},
					{
						content: '货源质量无法保障，货源供应容易中断',
					},
					{
						content: '生产周期长，易延迟交付',
					},
					{
						content: '市场的灵敏度低，产品品类单一',
					},
					{
						content: '采购、质检、装箱、发货、财务等工作消耗大量精力',
					},
					{
						content: '有限SKU，用户选择性少，产品同质化严重',
					}
				],
				afterSales: null,
				params: {
					nickname: '',
					phone: '',
					email: '',
					demand: ''
				}
			}
		},
		computed: {
			...mapState({
				bannerInfo: state => state.bannerInfo[2], // banner图
			})
		},
		created(){
			if(this.$route.params.tabIndex){
				this.chooseTab(this.$route.params.tabIndex)
			}
		},
		methods: {
			// 切换类型
			chooseTab(type) {
				this.tabIndex = type;
				if (type == 1) {
					this.getAfterSales();
				}

				// 调用混入的动画js计算滚动条
				this.$nextTick(() => {
					let selectArr = [
						'.first-box',
						'.second-box',
						'.after-sales-box',
						'.bg-box',
						'.join-box',
						'.address-box'
					]
					this.getOffsetTop(selectArr);
				})
			},
			// 获取售后服务
			getAfterSales() {
				this.$api.getSalesPolicy().then(res => {
					if (res.code == '0000') {
						this.afterSales = res.data;
					}
				})
			},
			// 联系我们
			submitFn() {
				if (!this.params.nickname) {
					this.$message(({
						message: '姓名不能为空！',
						type: 'error'
					}));
					return
				} else if (!this.params.phone) {
					this.$message(({
						message: '手机不能为空！',
						type: 'error'
					}));
					return
				} else if (!this.params.email) {
					this.$message(({
						message: '邮箱不能为空！',
						type: 'error'
					}));
					return
				} else if (!this.params.demand) {
					this.$message(({
						message: '需求不能为空！',
						type: 'error'
					}));
					return
				}

				// 提交数据
				this.$api.submitContact(this.params).then(res => {
					if (res.code == '0000') {
						this.$message({
							message: '提交成功，后续会有相关人员与您进行联系！'
						});
					} else {
						this.$message(({
							message: res.msg,
							type: 'error'
						}));
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.main-title {
		margin: 90px 0;
	}

	.tab-box {
		height: 60px;
		line-height: 60px;
		font-size: 18px;
		color: #666;
		border-radius: 30px;
		background: #fafafa;
		display: inline-block;

		.tab-item {
			width: 180px;
			display: inline-block;
			position: relative;
			transition: all .3s;
			cursor: pointer;
		}

		.tab-item:after {
			position: absolute;
			right: 0;
			top: 50%;
			height: 12px;
			width: 1px;
			display: block;
			content: "";
			background: #888;
			transform: translateY(-50%);
		}

		.tab-item:last-child:after {
			display: none;
		}

		.tab-item.active-cls {
			color: #fff;
			background: #22529A;
			border-radius: 30px;
		}

		.tab-item.active-cls:after {
			display: none;
		}

		.tab-item:hover {
			color: #fff;
			background: #22529A;
			border-radius: 30px;
		}
	}


	.advantage-box {
		margin-top: 70px;
		margin-bottom: 90px;

		.first-box {
			padding: 70px 0 10px;
			background: #fafafa;
		}
		.advantage-list{
			display: flex;
			flex-wrap: wrap;
			.advantage-item{
				width: 240px;
				height: 240px;
				background: #FFFFFF;
				border-radius: 60px;
				transition: all .3s;
				margin-right: 100px;
				margin-bottom: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				.text{
					margin-top: 31px;
					font-size: 24px;
				}
				&:nth-child(4n) {
					margin-right: 0;
				}
				&:hover {
					transform: scale(1.05);
				}
			}
			
		}
		.second-box {
			background: #fff;
			margin-top: 90px;

			.small-title {
				font-size: 24px;
				font-weight: 400;
				line-height: 50px;
			}

			.big-title {
				font-size: 50px;
				font-weight: bold;
				color: #333333;
				line-height: 50px;
			}

			.inline-box {
				margin-top: 70px;

				.left-box,
				.right-box {
					width: 600px;
					display: inline-block;
					border-radius: 10px;
					overflow: hidden;
					background: #fafafa;
				}

				.box-title {
					height: 80px;
					line-height: 80px;
					font-size: 36px;
					background: #ccc;
				}

				.box-content {
					padding: 50px 50px 1px;
				}

				.box-item {
					margin-bottom: 40px;
					background: #E6E6E6;
					border-radius: 20px;
					height: 40px;
					line-height: 40px;
					position: relative;
					color: #666;
					font-size: 16px;
				}

				.blue-cls {
					width: 110px;
					height: 40px;
					line-height: 40px;
					background: #22529A;
					border-radius: 20px;
					color: #fff;
					position: absolute;
					top: 0;
					right: 0;
				}
			}


			.left-box {
				margin-right: 60px;
				background: #E0EBFB;

				.box-title {
					background: #22529A;
					color: #fff;
				}
				.box-content{
					background: #E0EBFB;
				}
				.box-item {
					background: #fff;
				}
			}
		}

	}

	.after-sales-box {
		margin-top: 70px;
		margin-bottom: 50px;
		text-align: left;

		.title-cls {
			height: 40px;
			line-height: 40px;
			padding-left: 20px;
			background: #E6E6E6;
			border-bottom: 1px solid #ccc;
		}

		.left-box {
			width: 998px;
			border: 1px solid #CCCCCC;
			margin-right: 10px;
			display: inline-block;
			vertical-align: top;
			font-size: 14px;

			.left-content {
				padding: 0 20px;
			}

		}

		.right-box {
			width: 250px;
			display: inline-block;
			line-height: 22px;
			font-size: 14px;
		}

		.right-top {
			height: 260px;
			border: 1px solid #CCCCCC;
			margin-bottom: 10px;

			.top-content {
				padding: 10px 16px;
			}

		}

		.right-bottom {
			height: 690px;
			border: 1px solid #CCCCCC;
			margin-bottom: 10px;

			.bottom-content {
				padding: 10px 16px;
			}
		}
	}

	.cooperation-box {
		margin-top: 70px;

		.bg-box {
			color: #fff;

			.title-cls {
				font-size: 36px;
				margin-bottom: 20px;
			}

			.content-cls {
				width: 1260px;
				position: absolute;
				z-index: 1;
				left: 50%;
				transform: translateX(-50%);
			}

			.icon-cls {
				width: 194px;
				margin: 72px 0 46px;
			}
		}

	}

	.join-box {
		padding-bottom: 90px;

		.content-cls {
			margin-top: 79px;
			position: relative;
		}

		.form-box {
			box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
			padding: 105px 300px 85px;
			position: relative;

			.form-item {
				border-bottom: 1px solid #e6e6e6;
				width: 240px;
				display: inline-block;
				text-align: left;
				margin-right: 60px;
				margin-bottom: 60px;
			}

			.form-item:nth-child(2n) {
				margin-right: 0;
			}

			.label-name {
				font-size: 14px;
				color: #666;
				margin-bottom: 20px;
			}

			.input-cls {
				border: 0;
				font-size: 18px;
				width: 240px;
				outline: none;
				margin-bottom: 10px;
				height: 24px;
				line-height: 24px;
			}
		}

		.submit-btn {
			width: 350px;
			height: 60px;
			line-height: 60px;
			background: #EBB149;
			border-radius: 30px;
			text-align: center;
			font-size: 18px;
			position: absolute;
			bottom: -60px;
			left: 50%;
			transform: translate(-50%, -50%);
			cursor: pointer;
			transition: all .3s;
		}

		.submit-btn:hover {
			background: #f3c573;
		}

		.content-cls:before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-top: 100px solid transparent;
			border-left: 170px solid #f9f8f8;
			border-bottom: 100px solid transparent;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		.content-cls:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-top: 100px solid transparent;
			border-right: 170px solid #f9f8f8;
			border-bottom: 100px solid transparent;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.address-box {
		width: 100%;
		font-size: 0;

		img {
			width: 100%;
		}
	}

	.arrow-left {
		transform: rotate(-180deg);
		margin: 0 50px;
	}
	
	
	.arrow-down {
		transform: rotate(-120deg);
	}

	.distribution-box {
		background: #FAFAFA;
		margin-top: 70px;
		
		.content-cls{
			padding: 70px 0 50px;
		}
		
		.title-cls{
			font-size: 36px;
			color: #1A1A1A;
			margin-bottom: 50px;
		}
		.arrow{
			width: 54px;
			height: 42px;
			margin: 0 24px;
		}
	}

	.process-top , .process-bottom{
		display: flex;
		align-items: center;
		justify-content: center;
		.process-item {
			flex-shrink: 0;
			width: 240px;
			height: 230px;
			background: #fff;
			border-radius: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			overflow: hidden;
		}
		
		.icon-cls{
			height: 135px;
			width: 135px;
			margin-bottom: 30px;
		}
		
		.process-title{
			font-size: 24px;
			color: #1A1A1A;
		}
	}
	
	.process-content{
		display: flex;
		align-items: center;
		margin: 76px 0;
		
		.flex-1{
			flex: 1;
			text-align: center;
			font-size: 24px;
			color: #333;
		}
		
		.arrow-down{
			margin-right: 160px;
		}
	}
	
</style>
