<template>
	<div>
		<img class="img-bg" src="../assets/platform/top_bg.jpg" alt="">
		<div class="main-title">
			<div class="big-title">飞飞鱼供应链</div>
			<div class="small-title">— Flying fish —</div>
		</div>
		<div class="tab-box">
			<div v-for="item in tabList" :key="item.type" class="tab-item" :class="{'active-cls': item.type == tabIndex}" @click="chooseTab(item.type)">{{item.name}}</div>
		</div>
		<div class="advantage-box" v-if="tabIndex == 0">
			<div class="first-box">
				<div class="content-cls">
					<img class="img-cls" src="../assets/platform/advantage_1.png" alt="">
					<img class="img-cls" src="../assets/platform/advantage_2.png" alt="">
					<img class="img-cls" src="../assets/platform/advantage_3.png" alt="">
					<img class="img-cls" src="../assets/platform/advantage_4.png" alt="">
					<img class="img-cls" src="../assets/platform/advantage_5.png" alt="">
					<img class="img-cls" src="../assets/platform/advantage_6.png" alt="">
				</div>
			</div>
			<div class="second-box">
				<div class="content-cls">
					<div class="small-title">为什么</div>
					<div class="big-title">选择柔性定制</div>
					<div class="inline-box">
						<div class="left-box">
							<div class="box-title">柔性定制</div>
							<div class="box-content">
								<div class="box-item" v-for="(item, index) in leftList" :key="index">
									<span class="pad-left">{{item.content}}</span>
									<span class="blue-cls">{{item.tag}}</span>
								</div>
							</div>
						</div>

						<div class="right-box">
							<div class="box-title">传统模式</div>
							<div class="box-content">
								<div class="box-item" v-for="(item, index) in rightList" :key="index">
									<span class="pad-left">{{item.content}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="after-sales-box" v-if="tabIndex == 1">
			<div class="content-cls" v-if="afterSales">
				<div class="right-box">
					<div class="right-top">
						<div class="title-cls">前言</div>
						<div class="top-content" v-html="afterSales.preface"></div>
					</div>
					<div class="right-bottom">
						<div class="title-cls">订单售后规则</div>
						<div class="bottom-content" v-html="afterSales.order_rule"></div>
					</div>
				</div>
				<div class="left-box">
					<div class="title-cls">订单商品质量导致的售后问题，由平台承担全责.具体售后规则如下：</div>
					<div class="left-content" v-html="afterSales.main_rule"></div>
				</div>
			</div>
		</div>

		<div class="cooperation-box" v-if="tabIndex == 2">
			<div class="distribution-box">
				<div class="title-cls">分销流程</div>
				<div class="msg-cls">我们为分销商提供独有的智能服务平台<br>实现一件生产、一件代发服务，远销各个国家和平台</div>
				<div class="process-box">
					<div class="process-one">
						<div class="process-item">
							<img class="icon-cls" src="../assets/platform/distribution_1.png" alt="">
							<div class="process-title">开通账户</div>
						</div>
						<img class="arrow-left" src="../assets/platform/arrow.png" alt="">
						<div class="process-item">
							<img class="icon-cls" src="../assets/platform/distribution_2.png" alt="">
							<div class="process-title">选择产品</div>
						</div>
					</div>
					<div class="process-two">
						<img class="arrow-down arrow-down-1" src="../assets/platform/arrow.png" alt="">
						<div class="process-item">
							<img class="icon-cls" src="../assets/platform/distribution_4.png" alt="">
							<div class="process-title">设计产品</div>
						</div>
						<img class="arrow-right" src="../assets/platform/arrow.png" alt="">
						<div class="process-item">
							<!-- <img class="icon-cls" src="../assets/platform/distribution_3.png" alt=""> -->
							<div class="process-title">上传销售</div>
						</div>
						<img class="arrow-down arrow-down-2" src="../assets/platform/arrow.png" alt="">
					</div>
					<div class="process-three">
						<div class="process-item">
							<img class="icon-cls" src="../assets/platform/distribution_5.png" alt="">
							<div class="process-title">下单生产</div>
						</div>
						<img class="arrow-left" src="../assets/platform/arrow.png" alt="">
						<div class="process-item">
							<img class="icon-cls" src="../assets/platform/distribution_6.png" alt="">
							<div class="process-title">一件代发</div>
						</div>
					</div>
				</div>
			</div>

			<div class="join-box">
				<div class="content-cls">
					<div class="form-box">
						<div class="form-item">
							<div class="label-name">姓名</div>
							<input type="text" class="input-cls" v-model="params.nickname" value="飞飞鱼" placeholder="请输入您的姓名">
						</div>
						<div class="form-item">
							<div class="label-name">电话</div>
							<input type="text" class="input-cls" v-model="params.phone" placeholder="请输入您的手机号码">
						</div>
						<div class="form-item">
							<div class="label-name">邮箱</div>
							<input type="text" class="input-cls" v-model="params.email" placeholder="请输入您的邮箱">
						</div>
						<div class="form-item">
							<div class="label-name">需求</div>
							<input type="text" class="input-cls" v-model="params.demand" placeholder="请填写您的大概需求">
						</div>
						<div class="submit-btn" @click="submitFn">提交</div>
					</div>
				</div>
			</div>

			<!-- 地址 -->
			<div class="address-box ">
				<!-- <img src="../assets/address.jpg" alt=""> -->
				<van-image class="img-cls" :src="require('../assets/address.jpg')" fit="cover" lazy-load />
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				tabList: [{
						name: '平台优势',
						type: 0
					},
					{
						name: '售后服务',
						type: 1
					},
					{
						name: '合作加盟',
						type: 2
					}
				],
				tabIndex: 0, // 当前tab类型
				leftList: [{
						content: '无需备货，出单即赚，资金转化率高',
						tag: '出单即赚'
					},
					{
						content: '直连优质工厂，货源稳定',
						tag: '货源稳定'
					},
					{
						content: '柔性生产，入仓效率高',
						tag: '效率更高'
					},
					{
						content: '紧跟市场趋势，品类丰富',
						tag: '品类丰富'
					},
					{
						content: '无忧后勤，专注设计及销售',
						tag: '无忧后勤'
					},
					{
						content: '无限SKU，选择性多',
						tag: '无限SKU'
					}
				],
				rightList: [{
						content: '需求预测困难、订货、库存、周转滞销成本高',
					},
					{
						content: '货源质量无法保障，货源供应容易中断',
					},
					{
						content: '生产周期长，易延迟交付',
					},
					{
						content: '市场的灵敏度低，产品品类单一',
					},
					{
						content: '采购、质检、装箱、发货、财务等工作消耗大量精力',
					},
					{
						content: '有限SKU，用户选择性少，产品同质化严重',
					}
				],
				afterSales: null,
				params: {
					nickname: '',
					phone: '',
					email: '',
					demand: ''
				}
			}
		},
		methods: {
			// 切换类型
			chooseTab(type) {
				this.tabIndex = type;
				if (type == 1) {
					this.getAfterSales();
				}
			},
			// 获取售后服务
			getAfterSales() {
				this.$api.getSalesPolicy().then(res => {
					if (res.code == '0000') {
						this.afterSales = res.data;
					}
				})
			},
			// 联系我们
			submitFn() {
				if (!this.params.nickname) {
					this.$message(({
						message: '姓名不能为空！',
						type: 'error'
					}));
					return
				} else if (!this.params.phone) {
					this.$message(({
						message: '手机不能为空！',
						type: 'error'
					}));
					return
				} else if (!this.params.email) {
					this.$message(({
						message: '邮箱不能为空！',
						type: 'error'
					}));
					return
				} else if (!this.params.demand) {
					this.$message(({
						message: '需求不能为空！',
						type: 'error'
					}));
					return
				}

				// 提交数据
				this.$api.submitContact(this.params).then(res => {
					if (res.code == '0000') {
						this.$message({
							message: '提交成功，后续会有相关人员与您进行联系！'
						});
					} else {
						this.$message(({
							message: res.msg,
							type: 'error'
						}));
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.main-title {
		margin: 3rem 0;
	}

	.tab-box {
		height: 2.8rem;
		line-height: 2.8rem;
		font-size: 1rem;
		color: #666;
		border-radius: 1.2rem;
		background: #fafafa;
		display: inline-block;

		.tab-item {
			width: 7rem;
			display: inline-block;
			position: relative;
			transition: all .3s;
			cursor: pointer;
		}

		.tab-item:after {
			position: absolute;
			right: 0;
			top: 50%;
			height: 1rem;
			width: 1px;
			display: block;
			content: "";
			background: #888;
			transform: translateY(-50%);
		}

		.tab-item:last-child:after {
			display: none;
		}

		.tab-item.active-cls {
			color: #fff;
			background: #22529A;
			border-radius: 30px;
		}

		.tab-item.active-cls:after {
			display: none;
		}

		.tab-item:hover {
			color: #fff;
			background: #22529A;
			border-radius: 30px;
		}
	}


	.advantage-box {
		margin: 3rem 0;

		.first-box {
			padding: 3rem 0 1rem;
			background: #fafafa;
		}

		.img-cls {
			height: 10rem;
			width: 10rem;
			margin-right: 1.2rem;
			margin-bottom: 1.2rem;
			transition: all .3s;
		}

		.img-cls:nth-child(2n) {
			margin-right: 0;
		}
		
		.img-cls:hover {
			transform: scale(1.05);
		}

		.second-box {
			background: #fff;
			margin-top: 3rem;

			.small-title {
				font-size: 1rem;
				font-weight: 400;
				line-height: 2rem;
			}

			.big-title {
				font-size: 2rem;
				font-weight: bold;
				color: #333333;
				line-height: 2rem;
			}

			.inline-box {
				margin: 2rem 1rem;
				text-align: center;

				.left-box,
				.right-box {
					width: 100%;
					display: inline-block;
					border-radius: 1rem;
					overflow: hidden;
					background: #fafafa;
				}

				.box-title {
					height: 3rem;
					line-height: 3rem;
					font-size: 1.6rem;
					background: #ccc;
				}

				.box-content {
					padding: 2rem 1rem 0;
				}

				.box-item {
					margin-bottom: 1.6rem;
					background: #E6E6E6;
					border-radius: 1rem;
					height: 2rem;
					line-height: 2rem;
					position: relative;
					color: #666;
					text-align: left;
				}
				
				.pad-left{
					padding-left: 1.2rem;
				}

				.blue-cls {
					width: 5rem;
					height: 2rem;
					line-height: 2rem;
					background: #22529A;
					border-radius: 1rem;
					color: #fff;
					position: absolute;
					top: 0;
					right: 0;
					text-align: center;
				}
			}


			.left-box {
				margin-bottom: 2rem;
				background: #E0EBFB;

				.box-title {
					background: #22529A;
					color: #fff;
				}

				.box-item {
					background: #fff;
				}
			}
		}

	}

	.after-sales-box {
		margin: 2rem 0;
		text-align: left;
		padding: 0 1rem;

		.title-cls {
			padding: 0.25rem 1rem;
			background: #E6E6E6;
			border-bottom: 1px solid #ccc;
		}

		.left-box {
			width: 100%;
			border: 1px solid #CCCCCC;
			display: inline-block;
			vertical-align: top;
			font-size: 14px;
			
			.left-content{
				padding: 0 1rem;
			}
			
		}

		.right-box {
			line-height: 1.2rem;
			font-size: 0.32rem;
			display: flex;
			align-items: flex-start;
			margin-bottom: 1rem;
		}

		.right-top {
			height: 12rem;
			border: 1px solid #CCCCCC;
			margin-right: 0.6rem;

			.top-content {
				padding: 0.4rem 0.5rem;
			}

		}

		.right-bottom {
			height: 12rem;
			border: 1px solid #CCCCCC;

			.bottom-content {
				padding: 0.4rem 0.5rem;
			}
		}
	}

	.cooperation-box {
		margin-top: 3rem;

		.bg-box {
			color: #fff;
			
			.title-cls{
				font-size: 1rem;
				margin-bottom: 1rem;
			}
			
			.content-cls {
				width: 100%;
				position: absolute;
				z-index: 1;
				left: 50%;
				transform: translateX(-50%);
			}

			.icon-cls {
				width: 8rem;
				margin: 3rem 0 1rem;
			}
			
			.img-cls{
				height: 15rem;
			}
		}

	}

	.join-box {
		padding-bottom: 3rem;

		.content-cls {
			margin-top: 3rem;
			position: relative;
		}

		.form-box {
			box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
			padding: 3rem 1rem;
			position: relative;

			.form-item {
				border-bottom: 1px solid #e6e6e6;
				width: 16rem;
				display: inline-block;
				text-align: left;
				margin-bottom: 1.2rem;
			}

			.label-name {
				font-size: 0.9rem;
				color: #666;
				margin-bottom: 1rem;
			}

			.input-cls {
				border: 0;
				font-size: 1rem;
				width: 16rem;
				outline: none;
				margin-bottom: 0.6rem;
				height: 1.1rem;
				line-height: 1.1rem;
			}
		}

		.submit-btn {
			width: 90%;
			height: 3rem;
			line-height: 3rem;
			background: #EBB149;
			border-radius: 3rem;
			text-align: center;
			font-size: 1.1rem;
			position: absolute;
			bottom: -3rem;
			left: 50%;
			transform: translate(-50%, -50%);
			cursor: pointer;
			transition: all .3s;
		}
		
		.content-cls:before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-top: 3rem solid transparent;
			border-left: 4.5rem solid #f9f8f8;
			border-bottom: 3rem solid transparent;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		.content-cls:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-top: 3rem solid transparent;
			border-right: 4.5rem solid #f9f8f8;
			border-bottom: 3rem solid transparent;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	
	.address-box{
		font-size: 0;
		.img-cls{
			height: 15rem;
		}
	}
	
	.arrow-left {
		transform: rotate(-180deg);
		height: 3rem;
		width: 3rem;
		margin: 0 1rem;
	}
	
	.arrow-right{
		height: 3rem;
		width: 3rem;
		margin: 0 1rem;
	}
	
	.arrow-down {
		height: 3rem;
		width: 3rem;
		margin: 0 1rem;
		transform: rotate(-90deg);
	}
	
	.distribution-box{
		margin-top: 3rem;
		background: #FAFAFA;
		padding: 0 1rem 3rem;
		
		.msg-cls{
			font-size: 0.8rem;
			color: #999;
			padding-bottom: 2rem;
		}
		
		.title-cls{
			padding-top: 3rem;
			font-size: 1.2rem;
			margin-bottom: 2rem;
		}
		
		.process-one, .process-two, .process-three{
			display: flex;
			align-items: center;
		}
		
		.process-item {
			flex: 1;
			background: #fff;
			border-radius: 0.4rem;
			text-align: center;
			overflow: hidden;
			padding: 2rem;
		}
		
		.icon-cls{
			height: 5rem;
			width: 5rem;
			margin-bottom: 1rem;
		}
		
		.process-title{
			font-size: 0.8rem;
			color: #1A1A1A;
		}
		
		.process-two{
			margin: 6rem 0;
			position: relative;
		}
		
		.arrow-down-1{
			position: absolute;
			right: 2rem;
			top: -4.4rem;
		}
		
		.arrow-down-2{
			position: absolute;
			left: 2rem;
			bottom: -4.4rem;
		}
		
	}
</style>
